import {apolloModel} from "~/services/gql/vender/model";
import SelectCsvImportQuery from "~/gql/csv/SelectCsvImport.gql";
import SelectCsvImportDetailQuery from "~/gql/csv/SelectCsvImportDetail.gql";
import ImportCsvMutation from "~/gql/setting/csv/ImportCsv.gql";
import constImport from "~/const/import";

export const csvImport = {
  /**
   * インポートされた CSV リストを取得する
   * @returns Promise<String> 媒体
   * @param app
   * @param variables
   */
  async selectCsvImportAction(app, variables) {
    // GraphQLへCSV取得リクエスト
    const data = await apolloModel.query(app, SelectCsvImportQuery, variables);
    return data;
  },

  /**
   * CSVインポート登録
   * @param app
   * @param variables
   * @returns Promise<*>
   */
  async createCsvImport(app, variables) {
    // GraphQLCSVインポート登録
    const data = await apolloModel.mutate(app, ImportCsvMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   *
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectCsvImportDetail(app, variables) {
    const data = await apolloModel.query(app, SelectCsvImportDetailQuery, variables);
    return data;
  },
}
