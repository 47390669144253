import {apolloModel} from "~/services/gql/vender/model";
import CreateSaleOutputMutation from "~/gql/sale/CreateSaleOutput.gql";
import DeleteSaleOutputsMutation from  "~/gql/sale/DeleteSaleOutputs.gql";
import SelectSaleOutputs from "~/gql/sale/SelectSaleOutputs.gql";

export const saleOutput = {
  async selectSaleOutputAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectSaleOutputs, variables);
    return data;
  },

  /**
   * createSaleOutputAction
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async createSaleOutputAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, CreateSaleOutputMutation, variables);
    return data;
  },

  /**
   * createSaleOutputAction
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async deleteSaleOutputsAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, DeleteSaleOutputsMutation, variables);
    return data;
  }
}
