import {apolloModel} from "~/services/gql/vender/model";
import DeleteSalePostingsMutation from "~/gql/salePosting/DeleteSalePostings.gql";
export const salePosting = {

  /**
   * Delete sale posting action
   * @param app
   * @param adminId
   * @param salePostings
   * @returns {Promise<Promise<Awaited<void>>|Promise<never>>}
   */
  async deleteSalePostingAction(app, {adminId, salePostings}) {
    // 顧客削除リクエスト
    const variables = {adminId, salePostings};
    const data = await apolloModel.mutate(app, DeleteSalePostingsMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
