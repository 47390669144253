import {app} from "./gql/app.js";
import {response} from "./gql/response.js";
import {customer} from "./gql/customer.js";
import {dashboard} from "./gql/dashboard.js";
import {project} from "./gql/project.js";
import {customerHistory} from "./gql/customerHistory.js";
import {agreement} from "./gql/agreement";
import {media} from "./gql/media";
import {mail} from "./gql/mail";
import {mailTemplate} from "./gql/mailTemplate";
import {line} from "./gql/line";
import {template as maisokuTemplate} from "./gql/maisoku/template"
import {templateUserSetting as maisokuTemplateUserSetting} from "./gql/maisoku/templateUserSetting"
import {output as maisokuOutput} from "./gql/maisoku/output"
import {stepMail} from "./gql/stepMail";
import {documentTemplate} from "./gql/shop/documentTemplate";
import {staffMessage} from "./gql/shop/message";
import {propertyRangeTorihikitaiyo} from "./gql/setting/realEstateAgent/propertyRangeTorihikitaiyo";
import {staff} from "./gql/staff";
import {calendar} from "./gql/calendar.js";
import {advertise} from "./gql/advertise.js";
import {activity} from "./gql/activity.js";
import {csvImport} from "./gql/setting/csv/csvImport.js";
import {fileExport} from "./gql/fileExport.js";
import {achievement} from "./gql/achievement.js";
import {tag} from "./gql/setting/tag/tag";
import {announcement} from "./gql/setting/announcement/announcement";
import {achievementOutput} from "./gql/achievement_output.js";
import {shop} from "./gql/shop.js";
import {sale} from "./gql/sale.js";
import {saleTarget} from "./gql/saleTarget.js";
import {saleOutput} from "./gql/saleOutput.js";
import {salePosting} from "./gql/salePosting.js";

export const GqlService = {
  app,
  response,
  customer,
  dashboard,
  project,
  customerHistory,
  agreement,
  media,
  mail,
  mailTemplate,
  line,
  maisokuOutput,
  maisokuTemplate,
  maisokuTemplateUserSetting,
  stepMail,
  documentTemplate,
  staffMessage,
  propertyRangeTorihikitaiyo,
  staff,
  calendar,
  advertise,
  activity,
  csvImport,
  fileExport,
  achievement,
  tag,
  announcement,
  achievementOutput,
  shop,
  sale,
  saleTarget,
  saleOutput,
  salePosting,
}
