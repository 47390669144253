import {apolloModel} from "~/services/gql/vender/model";
import CreateAgreementMutation from "~/gql/agreement/CreateAgreement.gql";
import UpdateAgreementMutation from "~/gql/agreement/UpdateAgreement.gql";
import SelectAgreementsQuery from "~/gql/agreement/SelectAgreements.gql";
import SelectAgreementQuery from "~/gql/agreement/SelectAgreement.gql";
import DeleteAgreementMutation from "~/gql/agreement/DeleteAgreements.gql";
import SelectMonthlyAgreements from "~/gql/agreement/SelectMonthlyAgreements.graphql";
import SelectAgreementHistoriesQuery from "~/gql/agreement/SelectAgreementHistories.gql";
import SelectAgreementHistoryQuery from "~/gql/agreement/SelectAgreementHistory.gql";

export const agreement = {
  /**
   * 成約詳細取得処理
   * @returns Promise<String> 成約
   * @param app
   * @param agreement
   */
  async selectAgreementAction(app, agreement) {
    // GraphQLへ成約取得リクエスト
    const variables = {seiyaku: agreement};
    const data = await apolloModel.query(app, SelectAgreementQuery, variables);
    return Promise.resolve(data?.seiyaku);
  },

  /**
   * 成約作成処理
   * @returns Promise<String> 成約ID
   * @param app
   * @param variables {agreement}
   */
  async createAgreementAction(app, {agreement}) {
    // GraphQLへ成約作成リクエスト
    const variables = {seiyaku: agreement};
    const data = await apolloModel.mutate(app, CreateAgreementMutation, variables);
    return Promise.resolve(data?.createSeiyaku?.seiyaku);
  },

  /**
   * 成約更新
   * @returns Promise<String> 成約ID
   * @param app
   * @param variables {agreement}
   */
  async updateAgreementAction(app, {agreement}) {
    // GraphQLへ成約作成リクエスト
    const agreementClone = _.cloneDeep(agreement)
    agreement.seiyakuFiles ? _.map(agreementClone.seiyakuFiles, file => _.unset(file, 'enabled')) : '';
    const variables = {seiyaku: agreementClone};
    const data = await apolloModel.mutate(app, UpdateAgreementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectAgreementsAction(app, variables) {
    const data = await apolloModel.query(app, SelectAgreementsQuery, variables);
    return data;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async deleteAgreementsAction(app, variables) {
    return await apolloModel.mutate(app, DeleteAgreementMutation, variables);
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async selectMonthlyAgreementsAction(app, variables) {
    return await apolloModel.query(app, SelectMonthlyAgreements, variables);
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAgreementHistoriesAction(app, variables) {
    variables = {seiyaku: variables}
    const {seiyakuUpdateHistories: data} = await apolloModel.query(app, SelectAgreementHistoriesQuery, variables);
    return data.seiyakuUpdateHistories;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectAgreementHistoryAction(app, variables) {
    variables = {seiyakuUpdateHistory: variables};
    const {seiyakuUpdateHistory: data} = await apolloModel.query(app, SelectAgreementHistoryQuery, variables);
    return data;
  },

  /**
   * Action to update agreement with Sale Posting
   * @param app
   * @param agreement
   * @return {Promise<*>}
   */
  async updateAgreementWithSalePostingAction(app, {agreement}) {
    const agreementClone = _.cloneDeep(agreement)
    const variables = {seiyaku: agreementClone};
    const data = await apolloModel.mutate(app, UpdateAgreementMutation, variables);
    return data ? Promise.resolve(data) : Promise.reject('error');
  },
}
