
import {AppLogic} from "~/logics/app-logic";

import GlobalGetters from "~/mixins/GlobalGetters";
import SearchConditions from "~/mixins/SearchConditions";
import VLoading from "~/components/presentational/atoms/VLoading";
import VSubMenuAtom from "~/components/presentational/atoms/VSubMenu";
import {getStaffMail} from "../middleware/authenticated";
import SecureLS from "secure-ls";

import KeywordTextAtomV2 from '~/components/presentational/atoms/KeywordTextV2';
import constMenu from '~/const/menu';
import constCustomer from '~/const/customer';
import {getWordList} from "~/helper/common";

export default {
  components: {
    VLoading,
    KeywordTextAtomV2,
    VSubMenuAtom,
  },
  mixins: [
    GlobalGetters,
    SearchConditions,
  ],
  data() {
    return {
      //選択店舗・スタッフ
      activeMenu: false,
      isOpened: false,
      isSpMenuOpen: false,
      subPage: '',
      subPageName: {
        top: 'ダッシュボード',
        advertise: '広告設定',
        agreement: '成約',
        customer: '顧客',
        goals: '業績/成績',
        message: '一括送信 メール / LINE',
        project: '案件',
        response: '反響',
        shop: '店舗内',
        setting: '設定',
      },
      path: '',
      isShopBalloon: false,
      isProjectBalloon: false,
      isMenuBalloon: false,
      isAgreementBallon: false,
      searchConditions: {
        keywords: '',
      },
      // スクロールY座標
      scrollY: 0,
      // SPヘッダーを縮めるかどうか
      isScrollHeader: false,
    }
  },
  computed: {
    menuObject() {
      return Object.assign(constMenu);
    },
    defaultSelectedShop: {
      get() {
        if(this.loginUser){
          return this.shopMapList.find(shop => shop.key === this.loginUser.shop);
        }
        return this.shopMapList[0] ?? null;
      },
      set(value) {
        return value;
      }
    },
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        this.getSubPageName(to.path);
        this.isSpMenuOpen = false;
        this.$set(this.searchConditions, 'keywords', '');
      }
    },
    loginUser: {
      handler: async function (newLoginUser) {
        //遷移した際の店舗リスト、スタッフリスト取得
        await this.changeShop()
      },
      deep: true
    },
    queryParamKeyword(keywords) {
      if (keywords) {
        this.searchConditions.keywords = keywords;
      }
    },
  },
  async beforeCreate() {
    //ログインスタッフのメールアドレス取得
    const staffMail = await getStaffMail();
    //店舗・スタッフ情報を取得
    if (staffMail) {
      await AppLogic.selectStaffFromMail(this, {mail: staffMail, shop: this.loginUser.shop});
    } else {
      this.$store.dispatch('app/setStaffLoading', false);
      this.$store.dispatch('app/setHeadLoading', false);
    }
  },
  created() {
    this.searchConditions.keywords = this.queryParamKeyword;
    this.getSubPageName(this.$route.path);
  },
  mounted() {
    window.addEventListener('scroll', this.onScrollY);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollY);
  },
  methods: {
    // Change shop event
    async changeShop(event) {
      if (event) {
        await AppLogic.changeShopGlobal(this, {shopId: Number(event.key), shopCompanyId: event.company});
        return;
      }
      await AppLogic.changeShopGlobal(this, {shopId: this.loginUser.shop, shopCompanyId: this.loginUser.company});
    },
    navToggle: function (menu) {
      this.activeMenu = this.activeMenu === menu ? false : menu
    },
    getSubPageName: function (page_path) {
      if (page_path == '/') {
        this.path = 'top';
      } else {
        this.path = page_path.split('/')[1];
      }
      if (this.subPageName[this.path]) {
        this.subPage = this.subPageName[this.path];
      } else {
        this.subPage = this.subPageName['top'];
      }
    },
    toggleMenu: function () {
      this.isSpMenuOpen = !this.isSpMenuOpen;
    },
    logout: async function () {
      const ls = new SecureLS({isCompression: false});

      ls.remove('cms');
      localStorage.removeItem('ags_sso_staff');
      window.location.replace(process.env.TRUST_LOGIN_SIGN_OUT_URL);
    },

    showProjectBalloon: function () {
      this.isProjectBalloon = true;
    },
    hideProjectBalloon: function () {
      this.isProjectBalloon = false;
    },
    showShopBalloon: function () {
      this.isShopBalloon = true;
    },
    hideShopBalloon: function () {
      this.isShopBalloon = false;
    },
    redirectPageCustomer() {
      const routeName = this.$route.name;
      let conditions = {
        keywords: _.join(getWordList(this.searchConditions.keywords)),
        shopId: this.loginUser.shop,
        mainAdminId: constCustomer.defaultStaff,
        subAdminId: constCustomer.defaultStaff,
        page: constCustomer.pagination.initPage,
        target: constCustomer.defaultSortTarget,
        isAsc: false,
      };
      if (routeName === 'customer') {
        this.$root.$emit('set-select-main-staff');
        this.$router.push({query: conditions});
      } else {
        this.$router.push({
          name: 'customer',
          query: conditions,
        });
      }
    },
    // ページのスクロール位置を取得
    onScrollY: _.throttle(function () {
      // 処理を200msに一度実行
      this.scrollY = Number(window.scrollY);
      // ヘッダーが縮むとスクロールY座標が変わるため、ヘッダが大きいときとそうでないときでしきい値を変える
      let threshold = this.isScrollHeader == true ? 100 : 160;
      this.isScrollHeader = this.scrollY > threshold ? true : false;
    }, 200),

    // Shop filter on header
    shopFilter(item, queryText){
      const filterValue = item.value.toLowerCase()
      const searchText = queryText.toLowerCase()

      // Reminder: Watch the hooks.
      return (
        filterValue.indexOf(searchText) > -1
      )
    },
  }
}
