import {apolloModel} from "~/services/gql/vender/model";
import SelectStaffFromMailQuery from "~/gql/app/SelectStaffFromMail.gql";
import SelectShopDataFromKibouQuery from "~/gql/app/SelectShopDataFromKibou.gql";
import SelectStaffListQuery from "~/gql/app/SelectStaffList.gql";
import SelectTodohukenListQuery from "~/gql/app/SelectTodohukenList.gql";
import SelectSikucyosonListQuery from "~/gql/app/SelectSikucyosonList.gql";
import SelectCyoListQuery from "~/gql/app/SelectCyoList.gql";
import SelectCyomokuListQuery from "~/gql/app/SelectCyomokuList.gql";
import SelectSchoolListQuery from "~/gql/app/SelectSchoolList.gql";
import SelectEnsenEkiListQuery from "~/gql/app/SelectEnsenEkiList.gql";
import SelectPropertyListQuery from "~/gql/app/SelectPropertyList.gql";
import SelectPropertyQuery from "~/gql/app/SelectProperty.gql";
import SelectCompanyListQuery from "~/gql/app/SelectCompanyList.gql";
import SelectBasicPropertyRelationQuery from "~/gql/app/PropertyRelation/SelectBasicPropertyRelation.gql";
import SelectParentPropertyRelationQuery from "~/gql/app/PropertyRelation/SelectParentPropertyRelation.gql";
import SelectParentBasicPropertyRelationQuery
  from "~/gql/app/PropertyRelation/ParentPropertyRelation/SelectParentBasicPropertyRelation.gql";
import SelectParentPropertyLandRelationQuery
  from "~/gql/app/PropertyRelation/ParentPropertyRelation/SelectParentPropertyLandRelation.gql";
import SelectPropertyLandRelationQuery from "~/gql/app/PropertyRelation/SelectPropertyLandRelation.gql";
import SelectPropertyPriceRelationQuery from "~/gql/app/PropertyRelation/SelectPropertyPriceRelation.gql";
import SelectPropertySavedImagesRelationQuery from "~/gql/app/PropertyRelation/SelectPropertySavedImagesRelation.gql";
import SelectPropertyTagsRelationQuery from "~/gql/app/PropertyRelation/SelectPropertyTagsRelation.gql";
import SelectPropertyTransactionRelationQuery from "~/gql/app/PropertyRelation/SelectPropertyTransactionRelation.gql";
import {mergeData} from "~/helper/common";

export const app = {
  /**
   * システムメッセージをセット
   * @returns void
   * @param app
   * @param msg {isShow, isFlash, type, message}
   */
  async changeSystemMessage(app, msg) {
    // storeにデータを送る
    await app.$store.dispatch('app/changeSystemMessage', msg);
  },

  /**
   * 担当者のメールアドレスからスタッフ情報取得
   * @returns void
   * @param app
   * @param variables
   */
  async selectStaffFromMailAction(app, variables) {
    // GraphQLへ物件リスト
    const data = await apolloModel.query(app, SelectStaffFromMailQuery, {mail: variables.mail});
    await app.$store.dispatch('app/setStaffData', {data: data, shop: variables.shop});
    return data;
  },

  /**
   * スタッフリスト取得処理
   * @returns mixed
   * @param app
   * @param isStaffGlobalUpdate
   */
  async selectStaffListAction(app, {shopId, shopCompanyId}, isStaffGlobalUpdate = true) {
    // GraphQLから取得
    const variables = {shop: shopId};
    // set staff loading
    await app.$store.dispatch('app/setStaffLoading', isStaffGlobalUpdate);
    const data = await apolloModel.query(app, SelectStaffListQuery, variables);
    await app.$store.dispatch('app/setStaffLoading', false);
    // storeにデータを送る
    if (isStaffGlobalUpdate) {
      await app.$store.dispatch('app/setStaffList', {shopUsersByShop: data.shopUsersByShop, shopId: shopId, shopCompanyId: shopCompanyId});
    }

    return data;
  },

  /**
   * Call back to function selectStaffListAction
   */
  async selectStaffListForComponentsAction(app, {shopId, shopCompanyId}, isStaffGlobalUpdate) {
    return this.selectStaffListAction(app, {shopId, shopCompanyId}, isStaffGlobalUpdate);
  },

  /**
   * 都道府県の取得処理
   * @returns Promise
   * @param app
   */
  async selectTodohukenListAction(app) {
    // GraphQLへ都道府県取得リクエスト
    return await apolloModel.query(app, SelectTodohukenListQuery, null);
  },

  /**
   * 市区町村の取得処理
   * @returns Promise
   * @param app
   * @param variables {todohuken_cd}
   */
  async changeSikucyosonListAction(app, {todohuken_cd}) {
    // GraphQLへ市区町村取得リクエスト
    const variables = {todohuken_cd};
    return await apolloModel.query(app, SelectSikucyosonListQuery, variables);
  },

  /**
   * 町の取得処理
   * @returns Promise
   * @param app
   * @param variables {todohuken_cd, sikucyoson_cd}
   */
  async changeCyoListAction(app, {todohuken_cd, sikucyoson_cd}) {
    // GraphQLへ市区町村取得リクエスト
    const variables = {todohuken_cd, sikucyoson_cd};
    return await apolloModel.query(app, SelectCyoListQuery, variables);
  },

  /**
   * 丁目の取得処理
   * @returns Promise
   * @param app
   * @param variables {todohuken_cd, sikucyoson_cd, cyo_cd}
   */
  async changeCyomokuListAction(app, {todohuken_cd, sikucyoson_cd, cyo_cd}) {
    // GraphQLへ市区町村取得リクエスト
    const variables = {todohuken_cd, sikucyoson_cd, cyo_cd};
    return await apolloModel.query(app, SelectCyomokuListQuery, variables);
  },

  /**
   * 郵便番号から住所CDの取得
   * @returns Promise
   * @param app
   * @param zip
   */
  async changeZipAction(app, zip) {
    // GraphQLへ市区町村取得リクエスト
    const variables = {zip};
    return await apolloModel.query(app, SelectCyomokuListQuery, variables);
  },

  /**
   * 学校の取得処理
   * @returns void
   * @param app
   * @param variables {todohuken_cd, sikucyoson_cd}
   */
  async changeSchoolListAction(app, {todohuken, sikucyoson}) {
    // GraphQLへ学校取得リクエスト
    const categories = ["013", "014"]; // "013": 小学校 "014": 中学校
    const variables = {todohuken, sikucyoson, categories};
    const data = await apolloModel.query(app, SelectSchoolListQuery, variables);
    return data;
  },

  /**
   * 沿線・駅の取得処理
   * @returns void
   * @param app
   * @param variables {todohuken}
   */
  async changeEnsenEkiListAction(app, {todohuken}) {
    // GraphQLへ沿線・駅取得リクエスト
    const variables = {todohuken};
    const data = await apolloModel.query(app, SelectEnsenEkiListQuery, variables);
    return data;
  },

  /**
   * 物件一覧の取得処理
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async changePropertyListAction(app, variables) {
    // GraphQLへ物件リスト
    const promiseData = [
      apolloModel.query(app, SelectPropertyListQuery, variables),
      apolloModel.query(app, SelectBasicPropertyRelationQuery, variables),
      apolloModel.query(app, SelectParentPropertyRelationQuery, variables),
      apolloModel.query(app, SelectParentBasicPropertyRelationQuery, variables),
      apolloModel.query(app, SelectParentPropertyLandRelationQuery, variables),
      apolloModel.query(app, SelectPropertyLandRelationQuery, variables),
      apolloModel.query(app, SelectPropertyPriceRelationQuery, variables),
      apolloModel.query(app, SelectPropertySavedImagesRelationQuery, variables),
      apolloModel.query(app, SelectPropertyTagsRelationQuery, variables),
      apolloModel.query(app, SelectPropertyTransactionRelationQuery, variables),
    ]
    return Promise.all(promiseData)
      .then(responses => {
        return mergeData(responses);
      })
      .catch(error => {
        throw error;
      });
  },

  /**
   * メール物件の取得処理
   * @returns void
   * @param app
   * @param variables
   */
  async selectPropertyAction(app, variables) {
    // GraphQLへ物件リスト
    const data = await apolloModel.query(app, SelectPropertyQuery, variables);
    await app.$store.dispatch('app/setPropertyData', data.searchProperties.properties[0]);
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectPropertyInforAction(app, variables) {
    // GraphQLへ物件リスト
    const data = await apolloModel.query(app, SelectPropertyQuery, variables);
    return data.searchProperties.properties[0];
  },

  /**
   * 会社リストの取得処理
   * @returns Promise
   * @param app
   * @param variables {keyword, shop}
   */
  async selectCompanyListAction(app, {keyword, shop}) {
    // GraphQLへ会社リスト取得リクエスト
    const variables = {keyword, shop};
    return await apolloModel.query(app, SelectCompanyListQuery, variables);
  },

  /**
   * 物件情報画面に載せる店舗情報を取得
   * @returns void
   * @param app
   * @param variables
   */
  async selectShopDataFromKibouAction(app, variables) {
    // GraphQLへ物件リスト
    const data = await apolloModel.query(app, SelectShopDataFromKibouQuery, variables);
    await app.$store.dispatch('app/setShopDataFromKibou', data);
  },
}
