import {apolloModel} from "~/services/gql/vender/model";
import SelectSaleTargetsQuery from "~/gql/saleTarget/SelectSaleTargets.gql";
import UpsertSaleTargetsMutation from "~/gql/saleTarget/UpsertSaleTargets.gql";
export const saleTarget = {
  /**
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectSaleTargetsAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectSaleTargetsQuery, variables);
    return data;
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async upsertSaleTargetsAction(app, variables) {
    variables = Object.assign({upsertSaleGoalsInput: variables});
    const data = await apolloModel.mutate(app, UpsertSaleTargetsMutation, variables);
    return data;
  },
}
