import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12bd3fcc = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _c487f1dc = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _77111d96 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _40e6de49 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _60441ae9 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _8367e126 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _0f981f6b = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _675c2cf4 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _d71b62e2 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _122d1eb0 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _8070f0f8 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _3d61cf8c = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _e8b037dc = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _67b4db69 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _5ac19321 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _42fd11cc = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _6221d49a = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _07a3a529 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _616cdbdc = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _5ee63d1c = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _3eb26ed3 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _71a5d9a9 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _3ed6779d = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _bf0a2ea0 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _b57d7514 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _3e878122 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _737ed671 = () => interopDefault(import('../pages/sale/output.vue' /* webpackChunkName: "pages/sale/output" */))
const _f673689e = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _8204e862 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _360ac0f9 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _61b891a0 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _7394c646 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _6cec9e68 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _57a77784 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _09e9e574 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _794d32de = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _168dd0f9 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _36cd9836 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _52ef518e = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _2bc3f397 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _e881a5d2 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _6112b33a = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _7f8439fc = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _8f91569e = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _63f8af74 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _45f71c00 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _561fbfaa = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _2fd8d9c4 = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _10b87c68 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _a89198b0 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _e91f13a2 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _25403077 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _244519f0 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _da8d5a0c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0e05662d = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _43b9db92 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _ab19af5e = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _f64d0924 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _0a704eb7 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _212b5aa2 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _ec85aef4 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _6fb4f34e = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _4d257634 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _0ef58aec = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _371dbda1 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _2b5ca102 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _762a3cdd = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _77018776 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _68bdc391 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _b3deae92 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _1fd99aec = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _638e6afc = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _6d024554 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _68a4e944 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _21829498 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _9c137618 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _12bd3fcc,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _c487f1dc,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _77111d96,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _40e6de49,
    name: "calendar"
  }, {
    path: "/customer",
    component: _60441ae9,
    name: "customer"
  }, {
    path: "/details",
    component: _8367e126,
    name: "details"
  }, {
    path: "/goals",
    component: _0f981f6b,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _675c2cf4,
    name: "maisoku"
  }, {
    path: "/media",
    component: _d71b62e2,
    name: "media"
  }, {
    path: "/privacy",
    component: _122d1eb0,
    name: "privacy"
  }, {
    path: "/project",
    component: _8070f0f8,
    name: "project"
  }, {
    path: "/response",
    component: _3d61cf8c,
    name: "response"
  }, {
    path: "/sale",
    component: _e8b037dc,
    name: "sale"
  }, {
    path: "/achievement/output",
    component: _67b4db69,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _5ac19321,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _42fd11cc,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _6221d49a,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _07a3a529,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _616cdbdc,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _5ee63d1c,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _3eb26ed3,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _71a5d9a9,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _3ed6779d,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _bf0a2ea0,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _b57d7514,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _3e878122,
    name: "response-registration"
  }, {
    path: "/sale/output",
    component: _737ed671,
    name: "sale-output"
  }, {
    path: "/setting/announcement",
    component: _f673689e,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _8204e862,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _360ac0f9,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _61b891a0,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _7394c646,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _6cec9e68,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _57a77784,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _09e9e574,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _794d32de,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _168dd0f9,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _36cd9836,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _52ef518e,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _2bc3f397,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _e881a5d2,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _6112b33a,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _7f8439fc,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _8f91569e,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _63f8af74,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _45f71c00,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _561fbfaa,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _2fd8d9c4,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _10b87c68,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _a89198b0,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _e91f13a2,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _25403077,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _244519f0,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _da8d5a0c,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _0e05662d,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _43b9db92,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _ab19af5e,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _f64d0924,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _0a704eb7,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _212b5aa2,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _ec85aef4,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _6fb4f34e,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _4d257634,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _0ef58aec,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _371dbda1,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _2b5ca102,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _762a3cdd,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _77018776,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _68bdc391,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _b3deae92,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _1fd99aec,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _638e6afc,
    name: "property-property",
    children: [{
      path: "contact",
      component: _6d024554,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _68a4e944,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _21829498,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _9c137618,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
