import {apolloModel} from "~/services/gql/vender/model";
import SelectSales from "~/gql/sale/SelectSales.gql";
import DeleteSalesMutation from "~/gql/sale/DeleteSales.gql";
import UpsertSaleOutputStaffOrderMutation from "~/gql/sale/UpsertSaleOutputStaffOrder.gql";

export const sale = {
  /**
   * @param app
   * @param variables
   * @return {Promise<void>}
   */
  async selectSalesAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(app, SelectSales, variables);
    return data;
  },

  /**
   *
   * @param app
   * @param variables
   * @return {Promise<*>}
   */
  async deleteSalesAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.mutate(app, DeleteSalesMutation, variables);
    return data;
  },

  /**
   *
   * @param app
   * @param variables
   * @returns {Promise<void>}
   */
  async upsertSalesOutputStaffOrderAction(app, variables) {
    variables = Object.assign(variables);
    const data = await apolloModel.query(
      app,
      UpsertSaleOutputStaffOrderMutation,
      variables
    );
    return data;
  },
}
