import constCustomer from "~/const/customer";
import constType from "~/const/type";
import constDesiredCondition from "~/const/desiredCondition";
import constResponse from "~/const/response";
import constProject from "~/const/project";
import constProperty from "~/const/property";
import constCustomerHistory from "~/const/customerHistory";
import constCommon from "~/const/common";
import constMaisoku from "~/const/maisoku";
import _ from "lodash";
import mail from "~/const/mail";
import {imgExtension, videoExtension} from "~/const/regex";
import {publicFileDomain} from "~/const/publicFileDomain";
import {getLinePropertyHTML} from "./property";

/**
 * フルネームを取得する関数
 * @returns {string}
 * @param customer
 */
export const getFullName = (customer) => {
  return [customer['name_last'], customer['name_first']].join('') || constCommon.noDataText;
};

/**
 * 姓名を取得する関数かな
 * @returns {string}
 * @param customer
 */
export const getKanaName = (customer) => {
  return [customer['kana_last'], customer['kana_first']].join('') || constCommon.noDataText;
};

/**
 * 関数は顧客名を取得します
 * @param customer
 * @returns {string|*}
 */
export const getCustomerName = (customer) => {
  if (_.isEmpty(customer)) {
    return constCommon.noDataText;
  }
  if (customer?.company_client === constCustomer.isKojinValue) {
    return getFullName(customer);
  }
  if (customer?.company_client === constCustomer.isHojinValue) {
    return customer.company_name || constCommon.noDataText
  }
  if (customer?.name_first || customer?.name_last) {
    return getFullName(customer);
  }
  return constCommon.noDataText;
};

/**
 * Get customer kana name
 * @param customer
 * @returns {string|string|{allowNull: boolean, comment: string, type: *, validate: {len: number[], is: [string, string]}}|{allowNull: boolean, comment: string, type: *}|*}
 */
export const getCustomerKana = (customer) => {
  if (_.isEmpty(customer)) {
    return constCommon.noDataText;
  }
  if (customer.company_client === constCustomer.isKojinValue) {
    return getKanaName(customer);
  }
  if (customer.company_client === constCustomer.isHojinValue) {
    return customer.company_kana || constResponse.noDataText;
  }
  return constCommon.noDataText;
};

/**
 * 関数は列挙型でプロジェクトタイプを取得します
 * @param desiredConditionObj
 * @returns {*}
 */
export const getProjectType = (desiredConditionObj) => {
  if (_.isEmpty(desiredConditionObj)) {
    return constCommon.noDataText;
  }
  if (_.isArray(desiredConditionObj)) {
    desiredConditionObj = _.head(desiredConditionObj);
  }
  return constType.maps[desiredConditionObj?.anken_type || 'e'];
};

/**
 * Get project status (general function)
 * @param desiredConditionObj
 * @returns {*|string}
 */
export const getProjectStatus = (desiredConditionObj) => {
  if (_.isEmpty(desiredConditionObj)) {
    return constCommon.noDataText;
  }
  if (_.isArray(desiredConditionObj)) {
    desiredConditionObj = _.head(desiredConditionObj);
  }
  return !_.isNil(desiredConditionObj?.status) ? constProject.reg_project.status.both.find(status => status.value === desiredConditionObj.status)?.text : constCommon.noDataText;
};

/**
 * Get project Ondo (general function)
 * @param desiredConditionObj
 * @returns {*|string}
 */
export const getProjectOndo = (desiredConditionObj) => {
  if (_.isEmpty(desiredConditionObj)) {
    return constCommon.noDataText;
  }
  if (_.isArray(desiredConditionObj)) {
    desiredConditionObj = _.head(desiredConditionObj);
  }
  return !_.isNil(desiredConditionObj?.ondo) ? constProject.reg_project.expectation.find(expectation => expectation.value === desiredConditionObj.ondo)?.text : constCommon.noDataText;
};

/**
 * Get CustomerHistory Action (general function)
 * @param clientHistory
 * @returns {*|string}
 */
export const getCustomerHistoryAction = (clientHistory) => {
  return !_.isNil(clientHistory?.action_type) ? constCustomerHistory.action.find(customerHistory => parseInt(customerHistory.value) === parseInt(clientHistory.action_type))?.text : constCommon.noDataText;
};

/**
 * Calculation Date
 * @param date
 * @returns {string}
 */
export const calculationDate = (date, isJST = false) => {
  const currentDate = isJST ? $nuxt.$moment().add(9, 'H') : $nuxt.$moment();
  const months = Math.abs(currentDate.diff(date, 'month'));
  const weeks = Math.abs(currentDate.diff(date, 'week'));
  const days = Math.abs(currentDate.diff(date, 'day'));
  const hours = Math.abs(currentDate.diff(date, 'hours'));
  const minutes = Math.abs(currentDate.diff(date, 'minutes'));
  const seconds = Math.abs(currentDate.diff(date, 'second'));
  if (months) {
    return `${months}ヶ月以上前`
  }
  if (weeks) {
    return `${weeks}週間以上前`
  }
  if (days) {
    return `${days}日前`
  }
  if (hours) {
    return `${hours}時間前`
  }
  if (minutes) {
    return `${minutes}分前`
  }
  if (seconds) {
    return `${seconds}秒前`
  }
  return 'たった今'

};

/**
 * 関数は案件種別値を取得します
 * @param desiredConditionObj
 * @return {string|*}
 */
export const getKibouShubetsu = (desiredConditionObj) => {
  if (_.isEmpty(desiredConditionObj)) {
    return constCommon.noDataText;
  }
  if (_.isArray(desiredConditionObj)) {
    desiredConditionObj = _.head(desiredConditionObj);
  }
  let propertiesTypeName = constCommon.noDataText;
  if (getProjectType(desiredConditionObj)['name_en'] === 'buy') {
    const newKodate = desiredConditionObj['kibou_kodate_new'] ? constDesiredCondition.desiredType.kibouKodateNew : '';
    const oldKodate = desiredConditionObj['kibou_kodate_old'] ? constDesiredCondition.desiredType.kibouKodateOld : '';
    const newMansion = desiredConditionObj['kibou_mansion_new'] ? constDesiredCondition.desiredType.kibouMansionNew : '';
    const oldMansion = desiredConditionObj['kibou_mansion_old'] ? constDesiredCondition.desiredType.kibouMansionOld : '';
    const tochi = desiredConditionObj['kibou_tochi'] ? constDesiredCondition.desiredType.kibouTochi : '';
    const jigyo = desiredConditionObj['kibou_jigyo'] ? constDesiredCondition.desiredType.kibouJigyo : '';
    propertiesTypeName = [newKodate, oldKodate, newMansion, oldMansion, tochi, jigyo].join('');
  }

  return propertiesTypeName;
};

/**
 * エリアデータを取得する関数
 * @param areaObj
 * @param prefix
 * @returns {string}
 */
export const getArea = (areaObj, prefix = '') => {
  const areaArray = [];
  let todohuken = 'TodohukenRelation';
  let sikucyoson = 'SikucyosonRelation';
  let cyo = 'CyoRelation';
  let cyomoku = 'CyomokuRelation';
  prefix = _.camelCase(prefix);
  if (!prefix) {
    todohuken = _.camelCase(todohuken);
    sikucyoson = _.camelCase(sikucyoson);
    cyo = _.camelCase(cyo);
    cyomoku = _.camelCase(cyomoku);
  }
  areaObj[`${prefix}${todohuken}`] ? areaArray.push(areaObj[`${prefix}${todohuken}`].todohuken_nm) : '';
  areaObj[`${prefix}${sikucyoson}`] ? areaArray.push(areaObj[`${prefix}${sikucyoson}`].sikucyoson_nm) : '';
  areaObj[`${prefix}${cyo}`] ? areaArray.push(areaObj[`${prefix}${cyo}`].cyo_nm) : '';
  areaObj[`${prefix}${cyomoku}`] ? areaArray.push(areaObj[`${prefix}${cyomoku}`].cyomoku_nm) : '';
  return areaArray.join('');
}

/**
 * 住所データを取得する関数
 * @param addressObj
 * @param prefix
 * @returns {string}
 */
export const getAddress = (addressObj, prefix = '') => {
  prefix = prefix ? prefix + '_' : '';
  return `${getArea(addressObj, prefix)}${addressObj[`${prefix}syosai_jusyo`] ?? ''}${addressObj[`${prefix}tatemono`] ?? ''}`;
}

/**
 * 位置情報取得機能
 * @param addressObj
 * @param prefix
 * @returns {string}
 */
export const getLocation = (addressObj, prefix = '') => {
  prefix = prefix ? prefix + '_' : '';
  return `${getArea(addressObj, prefix)}${addressObj[`${prefix}banchi_ikou`] ?? ''}`;
}

/**
 * 交通情報を取得する機能
 * @param transportations
 * @param prefix
 * @returns {string}
 */
export const getTransportations = (transportations, prefix = '') => {
  if (!_.isEmpty(transportations)) {
    return _.map(
      _.filter(transportations, transportation => transportation[`${prefix}ensenRelation`] || transportation[`${prefix}ekiRelation`]),
      transportObj => {
        const transitArray = [];
        prefix = prefix ? prefix + '_' : '';
        prefix = _.camelCase(prefix);
        transportObj[`${prefix}ensenRelation`] ? transitArray.push(transportObj[`${prefix}ensenRelation`].ensen_nm) : '';
        transportObj[`${prefix}ekiRelation`] ? transitArray.push(`${transportObj[`${prefix}ekiRelation`].eki_nm}駅`) : '';
        return transitArray.join('');
      }).join('<br>');
  }
}

/**
 * プロパティタイプを取得する関数
 * @param property
 * @returns {*}
 */
export const getBukkenType = (property) => {
  const type = property.type;
  const kanri = property.basicProperty?.kanri;
  const shubetsu = property.basicProperty?.shubetsu;

  if (!shubetsu && type === "20" && !_.isNil(property.parentProperty)) {
    return constProject.shubetsuMansion;
  }

  if (type === "21" && !_.isNil(property.parentProperty)) {
    const shubetsuText = constProperty.bukkenShubetsu.find(e => e.value === property.parentProperty.basicProperty.shubetsu)?.text;
    return shubetsuText ?? constResponse.noDataText;
  }

  if (type !== "40" || shubetsu !== "1409") {
    const shubetsuText = constProperty.bukkenShubetsu.find(e => e.value === shubetsu)?.text;
    return shubetsuText ?? constResponse.noDataText;
  }
  return kanri === "1" ? "一棟マンション" : "区分マンション";
}

/**
 * プロパティのステータスを取得する関数
 * @param statusProperty
 * @returns {*}
 */
export const getBukkenStatus = (statusProperty) => {
  return statusProperty ? constResponse.statusProperty.find(status => status.value === statusProperty)?.text : constResponse.noDataText;
}

/**
 * プロパティの壁芯・内法を取得する関数
 * @param houshiki
 * @returns {*}
 */
export const getSenyuuHoushiki = (houshiki) => {
  return houshiki ? constProperty.senyuuMensekiHoushiki.find(mensekiHoushiki => mensekiHoushiki.value === houshiki)?.text : constResponse.noDataText;
}

/**
 * 不動産価格を取得する関数
 * @param price
 * @param type
 * @returns {string|*}
 */
export const getPrice = (price, type = constProperty.priceType.original) => {
  if (price) {
    price = price.toLocaleString();
    if (type === constProperty.priceType.original) {
      return price + '万円'
    } else if (type === constProperty.priceType.assumedYield) {
      return price + '％'
    } else if (type === constProperty.priceType.assumedIncome) {
      return price + '円 / 年'
    }
  }
  return constResponse.noDataText;
}

/**
 * プロパティの自社・他社を取得する関数
 * @param propertyTransaction
 * @returns {*}
 */
export const getAzukariSyubetu = (propertyTransaction) => {
  if (!propertyTransaction) {
    return constResponse.noDataText;
  }
  const azukariShubetsu = constResponse.azukari_shubetsu.find(target => target.value === propertyTransaction.azukari_shubetsu);
  return azukariShubetsu ? azukariShubetsu.text : constResponse.noDataText;
}

/**
 * プロパティの取引態様を取得する関数
 * @param propertyTransaction
 * @returns {*}
 */
export const getTorihikiTaiyou = (propertyTransaction) => {
  if (!propertyTransaction) {
    return constResponse.noDataText;
  }
  if (propertyTransaction.azukari_shubetsu === constResponse.azukari_company.otherCompany) { //他社の場合
    const torihikiTaiyou = constResponse.torihiki_taiyou.find(target => target.value === propertyTransaction.gyosha_torihiki_taiyo);
    return torihikiTaiyou ? torihikiTaiyou.text : constResponse.noDataText;
  }
  if (propertyTransaction.azukari_shubetsu === constResponse.azukari_company.ownCompany) { //自社の場合
    const torihikiTaiyou = constResponse.torihiki_taiyou.find(target => target.value === propertyTransaction.torihiki_taiyo);
    return torihikiTaiyou ? torihikiTaiyou.text : constResponse.noDataText;
  }
  return constResponse.noDataText;
}

/**
 *
 * @param date
 * @param format
 * @param isUtcJapan
 * @returns {*}
 */
export const getDateWithFormat = (date, format, isUtcJapan = false) => {
  return date ? $nuxt.$moment(date).utcOffset(isUtcJapan ? 9 : 0).format(format) : constResponse.noDataText;
}

/**
 * Get Japan date with format
 *
 * @param date
 * @param format
 * @returns {*}
 */
export const getJPDateWithFormat = (date, format) => {
  return date ? $nuxt.$moment(date).utcOffset(9).format(format) : constResponse.noDataText;
}

/**
 * Set Selected staff when change shop
 * @param staffMapList
 * @param staffId
 * @returns {number}
 */
export const setSelectedStaffWhenChangeShop = (staffMapList, staffId) => {
  let selected = 0;
  staffMapList.map((staff) => {
    if (staff.key === staffId) {
      selected = staffId;
    }
  });
  return selected;
};

/**
 * Is break line project status type
 * @param status
 * @returns {boolean}
 */
export const isBreakLineProjectStatusType = (status) => {
  return status === 'break';
};

/**
 * Set Item to Storage
 * @param keyItem
 * @param valueItem
 * @param childKeyItem
 */
export const setItemToLocalStorage = (keyItem, valueItem, childKeyItem = null) => {
  if (childKeyItem) {
    localStorage.setItem(keyItem, JSON.stringify({[childKeyItem]: valueItem}));
    return;
  }
  localStorage.setItem(keyItem, JSON.stringify(valueItem));
};

/**
 * Get item key in local storage
 * @param keyItem
 * @return {string|null|any}
 */
export const getLocalStorageItemAndParse = (keyItem) => {
  if (localStorage.hasOwnProperty(keyItem)) {
    if (localStorage.getItem(keyItem).length === 0 || localStorage.getItem(keyItem).startsWith('{[')) {
      return null;
    }
    if (localStorage.getItem(keyItem).startsWith('{')) {
      return JSON.parse(localStorage.getItem(keyItem));
    }
    return localStorage.getItem(keyItem);
  }
  return null;
};

/**
 * Remove Irrelevant keys in storage
 * @param toRoute
 * @param fromRoute
 */
export const removeIrrelevantKeysInStorage = (toRoute, fromRoute) => {
  const keysInStorage = Object.keys(localStorage);
  const blockRouteFrom = fromRoute.name?.split('-');
  const blockRouteTo = toRoute.name?.split('-');
  if (keysInStorage.length > 0 && blockRouteFrom && blockRouteTo) {
    keysInStorage.forEach((value, index) => {
      if (blockRouteFrom[0] === value && blockRouteTo[0] !== blockRouteFrom[0]) {
        localStorage.removeItem(value);
      }
    })
  }
};

/**
 * Is previous msg success
 * @param previousMsg
 */
export const isPreviousMsgSuccess = (previousMsg) => {
  return previousMsg.type === 'success';
};

/**
 * Replace Value object
 * @param object
 * @param character
 * @param characterReplace
 * @returns {*}
 */
export const replaceValueObj = (object, character, characterReplace) => {
  _.each(object, (item, key) => {
    if (_.isString(item)) {
      object[key] = object[key] === character ? characterReplace : object[key];
    } else {
      replaceValueObj(item, character, characterReplace);
    }
  });
  return object;
};

/**
 * Get desired conditions (project) shop user's name
 * @param desiredConditions
 * @param key
 * @return {string|*}
 */
export const getProjectShopUserName = (desiredConditions, key) => {
  if (_.isEmpty(desiredConditions)) {
    return constCommon.noDataText;
  }
  if (_.isArray(desiredConditions)) {
    return _.head(desiredConditions)[key] && _.head(desiredConditions)[key]?.deleted ? '削除されたユーザー' : _.head(desiredConditions)[key]?.name || constCommon.noDataText;
  }
  if (!_.isArray(desiredConditions)) {
    return desiredConditions[key] && desiredConditions[key]?.deleted ? '削除されたユーザー' : desiredConditions[key]?.name || constCommon.noDataText;
  }
  return constCommon.noDataText;
}

/**
 * @param key
 * @returns {[string, string, string]|[string, string, string, string]|[string, string, string, string, string, null, null, null, null]|[string, string, string, string, string, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]}
 */
export const getShubetsuGroupValues = (key) => {
  switch (key) {
    case '土地':
      return [
        constProject.BUKKEN_SHUBETSU_VALUE.LAND_FOR_SALE,
        constProject.BUKKEN_SHUBETSU_VALUE.LEASEHOLD_RIGHT,
        constProject.BUKKEN_SHUBETSU_VALUE.LAND,
      ];
    case '戸建':
      return [
        constProject.BUKKEN_SHUBETSU_VALUE.NEWLY_BUILT_DETACHED_HOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.USED_DETACHED_HOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.NEW_TERRACED_HOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.USED_TERRACED_HOUSE,
      ];
    case 'マンション':
      return [
        constProject.BUKKEN_SHUBETSU_VALUE.NEWLY_BUILT_CONDOMINIUM,
        constProject.BUKKEN_SHUBETSU_VALUE.SECONDHAND_APARTMENT,
        constProject.BUKKEN_SHUBETSU_VALUE.NEW_CONSTRUCTION_PUBLIC_CORPORATION_1,
        constProject.BUKKEN_SHUBETSU_VALUE.SECONDHAND_PUBLIC_CORPORATION_1,
        constProject.BUKKEN_SHUBETSU_VALUE.NEW_CONSTRUCTION_PUBLIC_CORPORATION_2,
        constProject.BUKKEN_SHUBETSU_VALUE.SECONDHAND_PUBLIC_CORPORATION_2,
        constProject.BUKKEN_SHUBETSU_VALUE.NEW_TOWNHOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.USED_TOWNHOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.RESORT_CONDOMINIUM_1,
      ];
    case '事業用':
      return [
        constProject.BUKKEN_SHUBETSU_VALUE.STORE,
        constProject.BUKKEN_SHUBETSU_VALUE.HOUSE_WITH_STORE,
        constProject.BUKKEN_SHUBETSU_VALUE.STORE_WITH_HOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.OFFICE,
        constProject.BUKKEN_SHUBETSU_VALUE.STORE_OR_OFFICE,
        constProject.BUKKEN_SHUBETSU_VALUE.BUILDING,
        constProject.BUKKEN_SHUBETSU_VALUE.PLANT,
        constProject.BUKKEN_SHUBETSU_VALUE.APARTMENT_1,
        constProject.BUKKEN_SHUBETSU_VALUE.WAREHOUSE,
        constProject.BUKKEN_SHUBETSU_VALUE.APARTMENT_2,
        constProject.BUKKEN_SHUBETSU_VALUE.DORMITORY,
        constProject.BUKKEN_SHUBETSU_VALUE.RYOKAN,
        constProject.BUKKEN_SHUBETSU_VALUE.HOTEL,
        constProject.BUKKEN_SHUBETSU_VALUE.VILLA,
        constProject.BUKKEN_SHUBETSU_VALUE.RESORT_CONDOMINIUM_2,
        constProject.BUKKEN_SHUBETSU_VALUE.MOTEL,
        constProject.BUKKEN_SHUBETSU_VALUE.CLINIC,
        constProject.BUKKEN_SHUBETSU_VALUE.GAS_STATION,
        constProject.BUKKEN_SHUBETSU_VALUE.SPECIAL_BATH,
        constProject.BUKKEN_SHUBETSU_VALUE.SAUNA,
        constProject.BUKKEN_SHUBETSU_VALUE.SANATORIUM,
        constProject.BUKKEN_SHUBETSU_VALUE.WORKING_PLACE,
        constProject.BUKKEN_SHUBETSU_VALUE.PARKING,
        constProject.BUKKEN_SHUBETSU_VALUE.OTHERS,
      ];
    default:
  }
};

/**
 * Get inquiry content
 * @param key
 * @returns {boolean|*}
 */
export const getInquiryContentTitle = (key) => {
  const inquiryContentTitle = mail.inquiryContentTitle;
  if (inquiryContentTitle[key]) {
    return inquiryContentTitle[key];
  }
  return key.replaceAll('_', ' ');
}

/**
 * get the age
 * @param birthday
 * @returns {string}
 */
export const getAge = (birthday) => {
  const birthdayDate = $nuxt.$moment(birthday).format('YYYY-MM-DD');
  return $nuxt.$moment().diff(birthdayDate, 'years', false);
}

/**
 * get month
 * @param month
 * @returns {number}
 */
export const getDiffMonth = (month) => {
  return month ? Math.abs($nuxt.$moment().month() - month) : '';
}

/**
 * @param data
 * @returns {*|string}
 */
export const getValidData = (data) => {
  if (_.isInteger(data)) {
    return !_.isNil(data) ? data : 0;
  } else {
    return !_.isEmpty(data) ? data : '';
  }
}

/**
 * @param currentName
 * @param newName
 */
export const changeFileName = (currentName, newName) => {
  return newName + '.' + currentName.split('.').pop();
}

/**
 * File type checker
 *
 * @param data
 */
export const fileTypeChecker = (data) => {
  if (imgExtension.exec(data)) {
    return 'image';
  }
  if (videoExtension.exec(data)) {
    return 'video'
  }
  return '';
}

export const getDialogBody = (history) => {
  let body = '';
  body += history?.lineReceiveRelation?.text ?? history?.lineClientRelation?.lineRelation?.message ?? history?.body ?? history?.lineClientRelation?.lineRelation?.err_body ?? '';
  if (!_.isEmpty(body)) {
    body += '<br>';
  }
  if (history.clientHistoryFiles.length > 0) {
    history.clientHistoryFiles.map((file) => {
      body += bodyHtml(file);
    });
  }
  if (!_.isNil(history?.lineReceiveRelation?.object_key)) {
    body = bodyHtml(history?.lineReceiveRelation, true, true);
  }
  const lineProperties = history?.lineClientRelation?.lineRelation?.lineProperties;
  if (lineProperties?.length) {
    for (const lineProperty of lineProperties) {
      if (!lineProperty.propertiesManagement) continue;
      body += getLinePropertyHTML(lineProperty.propertiesManagement);
    }
  }

  return body;
}

/**
 * Body html
 * @param file
 * @param isDisplayPreview
 * @param isLineReceive
 * @return {string}
 */
export const bodyHtml = (file, isDisplayPreview = true, isLineReceive = false) => {
  const fileObj = objFileGenerator(file, isLineReceive);
  let body = `${aTagHtml(fileObj.obj_url, fileObj.file_name)}<br>`;
  if (fileTypeChecker(fileObj.file_name) === 'image' && isDisplayPreview) {
    body += `${imgTagHtml(fileObj.obj_url)}<br>`;
  }
  if (fileTypeChecker(fileObj.obj_url) === 'video' && isDisplayPreview) {
    body += `${videoTagHtml(fileObj.obj_url)}<br>`;
  }

  return body;
}

/**
 * A tag html
 * @param objURL
 * @param filename
 * @return {`<a href="${string}" download="${string}">${string}</a>`}
 */
export const aTagHtml = (objURL, filename) => {
  return `<a href="${objURL}" download="${filename}">${filename}</a>`;
}

/**
 * Img tag html
 * @param objURL
 * @return {`<img src="${string}" alt="" height="150px" width="150px">`}
 */
export const imgTagHtml = (objURL) => {
  return `<img src="${objURL}" alt="" height="150px" width="150px">`;
}

/**
 * Video tag Html
 * @param objURL
 * @return {`<video controls width="250">
            <source
              src="${string}"
            >
              Sorry, your browser doesn't support embedded videos.
          </video>`}
 */
export const videoTagHtml = (objURL) => {
  return `<video controls width="250">
            <source
              src="${objURL}"
            >
              Sorry, your browser doesn't support embedded videos.
          </video>`;
}

/**
 * Get download URL from filepath
 *
 * @param filename
 * @returns {`https://${*}/${string}`}
 */
export const getDownloadURL = (filename) => {
  return `https://${publicFileDomain()}/${filename}`;
}

/**
 * Check LoginUser company and Customer kigyo
 * @param userCompany
 * @param kigyo
 */
export const checkKigyo = (userCompany, kigyo) => {
  return kigyo !== userCompany ? $nuxt.error({statusCode: 404, message: 'この情報は表示できません'}) : null;
}

export const objFileGenerator = (file) => {
  const subfix = _.get(file, 'object_key', file.file_title);
  const objURL = `https://${publicFileDomain()}/${subfix}`;

  return {obj_url: objURL, file_name: _.get(file, 'object_key', file.file_name)};
}

/**
 *
 * @param dataList
 * @returns {*[]}
 */
export const mergeData = (dataList) => {
  let data = [];
  _.forEach(dataList, (value) => {
    data = _.merge(data, value);
  });
  return data
}

/**
 * Merge new object to current object
 * @param propObj
 * @param targetObj
 */
export const deepMerge = (propObj, targetObj) => {
  for (let key in targetObj) {
    if (!targetObj.hasOwnProperty(key)) continue;
    $nuxt.$set(propObj, key, targetObj[key]);
  }
}

/**
 * Split a sentence by space (full-width and half-width) when it's a string
 * Return itself when it's an array
 *
 * @param sentence
 * @returns {null|string[]|*}
 */
export const getWordList = (sentence) => {
  if (typeof sentence === 'string') {
    return sentence.split(/[ 　]/u).filter(v => !!v)
  }
  if (_.isArray(sentence)) {
    return sentence;
  }
  return null
}

/**
 * Get TagXXXMaster name
 * @param project
 * @return {string}
 */
export const getTagMastersName = (project) => {
  let fullTagMastersName = '';
  if (_.isEmpty(project)) {
    return constCommon.noDataText;
  }
  const tagMasters = project?.clientRelation?.tags[0];
  _.map(tagMasters, function (tagMaster) {
    if (!_.isEmpty(tagMaster)) {
      fullTagMastersName += tagMaster.tag_name + ' ';
    }
  });
  if (_.isEmpty(fullTagMastersName)) {
    return constCommon.noDataText;
  }

  return fullTagMastersName;
}

/**
 * Get type of property
 * @param typeProperty
 * @returns {*}
 */
export const getPropertyType = (typeProperty) => {
  return !_.isNull(typeProperty) ? constMaisoku.propertyTypes.find(type => type.value === typeProperty)?.text : constCommon.noDataText;
}

/**
 * Split string to array by ',' and ' '
 * @param str
 * @return {unknown[]|null|*}
 */
export const explode = (str) => {
  if (_.isNil(str)) {
    return null;
  }
  if (_.isString(str)) {
    return _.filter(_.map(_.split(str, /[,\s]+/), prop => _.trim(prop)), num => !_.isNaN(parseInt(num)));
  }
  if (_.isArray(str)) {
    return str;
  }
  return null;
}
