
export default {
  name: 'VonInputText',
  props: {
    value: {
      type: [String, Number],
      require: true
    },
    placeholder: {
      type: String,
      require: true
    },
    input: {
      type: Event,
      require: true
    },
    type: {
      type: String,
      require: false,
      default: () => 'text',
    },
    min: {
      type: String,
      require: false,
      default: () => '0',
    },
    max: {
      type: String,
      require: false,
      default: () => '',
    },
    step: {
      type: String,
      require: false,
      default: () => '1',
    },
    isDisabled: {
      type: Boolean,
      require: false,
      default: () => false,
    }
  },
  methods: {
    handleChangeInput(event) {
      let inputValue = event.target.value;
      if (this.type === 'number' && inputValue.length > 12) {
        inputValue = inputValue.slice(0, 12);
      }
      this.$emit('input', inputValue);
    },
    preventExtraInput(event) {
      if (this.type === 'number' && event.target.value.length >= 12 && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
        event.preventDefault();
      }
    },
  },
};
